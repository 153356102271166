import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import PageTitle from '../components/page_title'
import PageContents from '../components/page_contents'
import * as style from '../styles/mission.module.css'

export default function Mission() {
  return (
    <Layout showFooter={true}>
      <Seo
        title='Mission'
        description='The Linden Law Group is dedicated to excellence in everything we do. Click here to read about our guiding principles.'
      />

      <PageContents>
        <PageTitle>Why Us</PageTitle>

        <div className={`${style.main} wide_content`}>
          <span className={style.title}>Our Mission Statement</span>
          <span className={style.text}>
            <span style={{ 'fontWeight': 'bold', 'fontStyle': 'italic' }}>
              "To provide superior, personalized legal services designed to
              achieve our client’s goals at a reasonable price and within a
              reasonable timeframe."
            </span>
            <br />
            <br />
            At Linden Law Group, our mission is to provide superior,
            personalized legal services designed to achieve our client’s goals
            at a reasonable price and within a reasonable timeframe. Through our
            talent, experience, and sheer dedication, we help our clients
            navigate challenging litigation, obtain and protect essential and
            valuable regulatory licenses, and recognize and manage a variety of
            other small business needs. We demonstrate to our clients that they
            have engaged a dedicated team that will fight relentlessly for them.
            We also take pride in the lasting personal relationships we
            regularly forge with our clients, as the result of our sincere
            desire to help them achieve success. We promise ongoing
            communication, constant analyzing and re-analyzing of our legal
            strategies, and a team approach to every matter. The professionals,
            business owners, and friends we count as clients are deeply
            important to us. As longtime community members, we have a personal
            stake in the people and businesses that make this community great.
          </span>
        </div>
      </PageContents>
    </Layout>
  )
}
